<script>
import pagination from "@/mixins/pagination";
import taskDefaultList from "@/mixins/taskDefaultList";

export default {
  name: "RecommendationDefaultList",

  mixins: [pagination, taskDefaultList],

  data() {
    return {
      configType: "recommendations",
    };
  },
};
</script>

<template lang="pug">
  section
    header.headline
      .headline__title
        h1
          | Recomendaciones
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input(prefix-icon="el-icon-search" v-on:input="debounceInput" v-model="searchInput" placeholder="Buscar" :clearable="true")
        router-link.button.button--blue(:to="{name: 'create-task', params: { taskType: 'recommendations' }}" v-auth="'task-default.create'")
          micon(name="add_circle_outline").button__icon
          span.button__text Nuevo Recomendación
    .box.box--with-subnav
      .box__content--stretch
        table
          thead
            tr
              th Nombre de la Recomendación
              th Descripción
              th Acciones
          tbody
            spinner(v-if="isLoading && !tasks.length")
            tr(v-else, v-for="r in tasks", :key="r._id")
              td {{ r.name }}
              td {{ r.description }}
              td.actions
                .actions-container
                  button.button.button--action(@click="edit(r._id)")
                    micon(name="edit")
                  button.button.button--action(@click="remove(r)")
                    micon(name="delete_forever")
      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit",
        :total="pagination.total",
        @pagination="setPagination"
      )
</template>
