<script>
import Spinner from "@/components/ui/Spinner";
import defaultTaskService from "@/services/default-task";
import FileUpload from "@/components/ui/FileUpload";
import fileUploadConfigService from "@/services/fileupload-config";

const CONFIG_TYPE = "vitals";
const VITAL_CONFIG = {
  name: "",
  description: "",
  answerValues: [
    {
      name: "",
      measurement: {
        green: {
          min: "",
          max: "",
        },
        yellow: {
          min: "",
          max: "",
        },
        red: {
          min: "",
          max: "",
        },
      },
    },
  ],
  responses: [],
};

export default {
  name: "VitalDefaultDetail",

  components: {
    Spinner,
    FileUpload,
  },

  data() {
    return {
      isLoading: false,
      isUpdate: this.$route.name === "update-vital",
      vitalConfigId: this.$route.params.id,
      vitalConfig: VITAL_CONFIG,
      isFetchingVitalConfig: false,
      isPostingVitalConfig: false,
      showAnswerError: false,
      showResponseError: false,
      colorNames: [
        { label: "Roja", value: "red" },
        { label: "Amarilla", value: "yellow" },
        { label: "Verde", value: "green" },
      ],
      hasFiles: false,
      showMediaResponseError: false,
    };
  },

  created() {
    this.getVitalConfig();
  },

  watch: {
    "$route.name"(name) {
      this.isUpdate = name === "update-vital";
      if (!this.isUpdate) {
        this.vitalConfig = Object.assign({}, VITAL_CONFIG);
      }
    },
    "$route.params.id"(id) {
      this.vitalConfigId = id;
      this.getVitalConfig();
    },
  },

  computed: {
    uploadMediaEndpoint() {
      return fileUploadConfigService.getTaskMediaEndpoint(
        "vitals",
        "default",
        this.vitalConfigId,
        "response"
      );
    },
  },

  methods: {
    endSubmit() {
      this.$router.push({
        name: "treatment-vital",
      });

      if (this.isUpdate) {
        this.$bus.$emit("vital-config-update", this.vitalConfig);
      } else {
        this.$bus.$emit("vital-config-create", this.vitalConfig);
      }

      this.isPostingVitalConfig = false;
    },

    isUsed(colorName) {
      return this.vitalConfig.responses.some(
        (response) => response.color === colorName
      );
    },

    submit() {
      this.createOrUpdateVitalConfig();
    },

    async createOrUpdateVitalConfig() {
      this.isPostingVitalConfig = true;

      const serviceCall = this.isUpdate
        ? defaultTaskService.update
        : defaultTaskService.create;

      const { data } = await serviceCall(this.vitalConfig, CONFIG_TYPE);
      if (!this.vitalConfigId) {
        this.vitalConfigId = data._id;
      }

      if (
        this.$refs.fileUpload &&
        this.$refs.fileUpload.length &&
        this.hasFiles
      ) {
        this.$refs.fileUpload.forEach((fileUpload) => fileUpload.startUpload());
      } else {
        this.endSubmit();
      }
    },

    deleteVitalConfig() {
      this.isLoading = true;
      defaultTaskService
        .delete(this.vitalConfigId, CONFIG_TYPE)
        .then(() => {
          this.$router.push({
            name: "treatment-vital",
          });
          this.$bus.$emit("vital-config-removed", this.vitalConfigId);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getVitalConfig() {
      if (this.isUpdate) {
        this.isLoading = true;
        this.isFetchingVitalConfig = true;
        defaultTaskService
          .getById({ taskID: this.vitalConfigId, taskType: CONFIG_TYPE })
          .then((vitalConfig) => {
            this.vitalConfig = vitalConfig;
          })
          .finally(() => {
            this.isLoading = false;
            this.isFetchingVitalConfig = false;
          });
      }
    },

    addAnswer() {
      this.showAnswerError = false;
      const lastAnswer =
        this.vitalConfig.answerValues[this.vitalConfig.answerValues.length - 1];

      if (
        !!lastAnswer &&
        (!lastAnswer.measurement.green.min ||
          !lastAnswer.measurement.green.max ||
          !lastAnswer.measurement.yellow.min ||
          !lastAnswer.measurement.yellow.max ||
          !lastAnswer.measurement.red.min ||
          !lastAnswer.measurement.red.max ||
          !lastAnswer.name)
      ) {
        this.showAnswerError = true;
        return;
      }

      this.vitalConfig.answerValues.push({
        name: "",
        measurement: {
          green: {
            min: "",
            max: "",
          },
          yellow: {
            min: "",
            max: "",
          },
          red: {
            min: "",
            max: "",
          },
        },
      });
    },

    removeAnswer(index) {
      this.showAnswerError = false;
      this.vitalConfig.answerValues.splice(index, 1);
    },

    getColorName(color) {
      const colorName = this.colorNames.find((elem) => color === elem.value);

      return colorName?.label || "SELECCIONE ALERTA";
    },

    resetMediaContent(media) {
      if (media.type === "video") {
        media.content = [{ originalFileName: "" }];
      } else {
        media.content = [];
      }
    },

    addMedia(response) {
      this.showMediaResponseError = false;
      const lastMediaResponse = response.media[response.media.length - 1];

      if (
        lastMediaResponse &&
        (!lastMediaResponse.title || !lastMediaResponse.type)
      ) {
        this.showMediaResponseError = true;
        return;
      }

      response.media.push({
        title: "",
        type: "",
        content: [],
      });
    },

    removeMedia(response, mediaIndex) {
      this.showMediaResponseError = false;
      response.media.splice(mediaIndex, 1);
    },

    getDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        uploadMultiple: true,
        maxFiles: 3,
        maxFilesize: 5,
      };
    },

    getFiles(media) {
      return media.content.slice();
    },

    onFilesAdded(file, media) {
      this.hasFiles = true;
      media.content.push({ originalFileName: file.name });
    },

    onFileRemoved(file, media) {
      media.content.splice(
        media.content.findIndex(
          (addedFile) => addedFile.originalFileName === file.name
        ),
        1
      );
    },

    onFilesRemoved() {
      this.hasFiles = this.vitalConfig.responses.some((response) => {
        return response.media.some((media) => media.content.length);
      });
    },

    addResponse() {
      this.showResponseError = false;
      const lastResponse =
        this.vitalConfig.responses &&
        this.vitalConfig.responses[this.vitalConfig.responses.length - 1];

      if (!!lastResponse && !lastResponse.color) {
        this.showResponseError = true;
        return;
      }

      if (!this.vitalConfig.responses) {
        this.$set(this.vitalConfig, "responses", [
          { color: "", message: { text: "", textColor: "" }, media: [] },
        ]);
      } else {
        this.vitalConfig.responses.push({
          color: "",
          message: { text: "", textColor: "" },
          media: [],
        });
      }
    },

    removeResponse(index) {
      this.showResponseError = false;
      this.vitalConfig.responses.splice(index, 1);
    },

    onFileUploadSuccess() {
      const isCompleted =
        this.$refs.fileUpload &&
        this.$refs.fileUpload.every((fileUpload) => {
          return fileUpload.$refs.uploader.dropzone.files.every(
            (file) => file.status === "success" || file.manuallyAdded
          );
        });
      if (isCompleted) {
        this.hasFiles = false;
        this.endSubmit();
      }
    },

    onMediaError() {
      this.isPostingVitalConfig = false;
    },
  },
};
</script>

<template lang="pug">
  section.detail(v-if="isLoading")
    spinner
  ValidationObserver(v-else v-slot="{handleSubmit}")
    form.detail(@submit.prevent="handleSubmit(submit)")
      header.detail__header
        h2.detail__headline {{ vitalConfig.name | capitalize }}
        .detail__actions(v-auth="'task-default.create'")
          el-button(v-if="isUpdate" type="danger" :disabled="vitalConfig.isNative" @click="deleteVitalConfig") Borrar
          el-button(type="primary" native-type="submit" :loading="isPostingVitalConfig") Guardar
      p.subtitle Los campos con (*) son obligatorios
      .modal__content(v-if="isFetchingVitalConfig")
        spinner

      .detail__content(v-else)
        .row
          fieldset.detail__field
            label.label * Nombre
            ValidationProvider(name="Nombre" rules="required" v-slot="{errors}")
              el-input(
                v-model="vitalConfig.name"
                autofocus
                :disabled="vitalConfig.isNative"
              )
              span.has-error {{ errors[0] }}
        .row
          fieldset.detail__field
            label.label Descripción
            el-input(
              type="textarea"
              v-model="vitalConfig.description"
              rows="3"
              maxlength="250"
            )
        .answers(v-for="(answer, index) in vitalConfig.answerValues")
          .row
            fieldset.detail__field
              label.label * Nombre Respuesta {{index + 1}}
              ValidationProvider(name="Nombre" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.name"
                )
                span.has-error {{errors[0]}}

          .row
            fieldset.detail__field
              el-checkbox(v-model="answer.compareToLastMeasurement")
                | Comparar contra última medición

          .row
            fieldset.detail__field
              label.label * Correcto Mínimo
              ValidationProvider(name="Correcto Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.green.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Correcto Máximo
              ValidationProvider(name="Correcto Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.green.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .row
            fieldset.detail__field
              label.label * Tolerable Mínima
              ValidationProvider(name="Desviación tolerable Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.yellow.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Tolerable Máxima
              ValidationProvider(name="Desviación tolerable Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.yellow.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .row
            fieldset.detail__field
              label.label * Anormal Mínimo
              ValidationProvider(name="Desviación anormal Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.red.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Anormal Máximo
              ValidationProvider(name="Desviación anormal Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.red.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .modal__row
            fieldset.modal__field
              small
                a(@click="removeAnswer(index)") &times; Eliminar Respuesta {{ index + 1 }}

        
        .modal__row
          fieldset.modal__field
            span.days-error(v-if="!!vitalConfig.answerValues && showAnswerError") Debes completar la respuesta para poder agregar una nueva

        .modal__row.days__add
          fieldset.modal__field  
            a(@click="addAnswer") + Agregar Respuesta
        
        .response(v-for="(response, index) in vitalConfig.responses" :key="index")
          .modal__row
            fieldset.modal__field
              label.label
                b Respuesta {{getColorName(response.color)}}
            el-button(type="danger" plain @click="removeResponse(index)") &times;
          .row
            fieldset.detail__field
              label.label * Alerta:
              ValidationProvider(name="Alerta" rules="required" v-slot="{errors}")
                el-select(
                    v-model="response.color"
                    placeholder='Seleccionar nivel Alerta'
                    filterable
                    clearable
                    default-first-option
                  )
                    el-option(
                      v-for="colorName in colorNames"
                      :key="colorName.value"
                      :label="colorName.label"
                      :value="colorName.value"
                      :disabled="isUsed(colorName.value)"
                    )
            
            fieldset.detail__field
              label.label * Mensaje:
              ValidationProvider(name="Mensaje" rules="required" v-slot="{errors}")
                el-input(
                  v-model="response.message.text"
                )
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label Color mensaje:
              el-color-picker(
                placeholder="Seleccione un color de texto"
                type="color"
                clearable
                v-model="response.message.textColor"
                name="Color"
                rows="1"
              )

          .response-media(
            v-for='(media, mediaIndex) in response.media'
          )
            fieldset.modal__field
              label.label
                b Recomendación {{mediaIndex +1}}

            .row
              fieldset.modal__field
                label.label * Tipo de Recomendación:
                ValidationProvider(:name="`Tipo Archivo Respuesta ${getColorName(response.color)}`", rules="required", v-slot="{ errors }")
                  el-select(v-model="media.type" v-on:change="resetMediaContent(media)")
                    el-option(label="Imagen" value="image")
                    el-option(label="Audio" value="audio")
                    el-option(label="Video" value="video")
                  span.has-error {{ errors[0] }}

              fieldset.modal__field
                label.label * Título:
                ValidationProvider(:name="`Titulo Archivo Respuesta ${getColorName(response.color)}`", rules="required", v-slot="{ errors }")
                  el-input(v-model="media.title")
                  span.has-error {{ errors[0] }}

              fieldset.modal__field
                label.label Descripción:
                el-input(v-model="media.description")

            .modal__row(v-if="media.type === 'image' || media.type === 'audio'")
              fieldset.modal__field
                label.label(v-if="media.type === 'image'") Imagen:
                label.label(v-else) Audio:
                file-upload(
                  ref="fileUpload",
                  :type="media.type",
                  :url="uploadMediaEndpoint",
                  :files="getFiles(media)",
                  :dropzone-options="getDropdownOptions()"
                  @file-added="(file) => onFilesAdded(file, media)",
                  @file-removed="(file) => onFileRemoved(file, media)"
                  @files-removed="onFilesRemoved"
                  @fileupload-success="onFileUploadSuccess"
                  @fileupload-error="onMediaError"
                )

            .modal__row(v-if="media.type === 'video'")
              fieldset.modal__field
                label.label * Link del Video:
                ValidationProvider(name="Link", rules="required|url", v-slot="{ errors }")
                  el-input(v-model="media.content[0].value", :class="{'has-error': errors[0]}")
                  span.has-error {{ errors[0] }}

            .modal__row
              fieldset.modal__field
                small
                  a.remove(@click="removeMedia(response, mediaIndex)") &times; Eliminar recomendación {{mediaIndex +1}}
                  
          .row
            fieldset.modal__field
              span.response__error(v-if="response.media.length && showMediaResponseError") Debes completar la recomendación de la respuesta {{ getColorName(response.color) }} para poder agregar una nueva
              a(@click="addMedia(response)") + Agregar recomendación

          .row
            fieldset
              span.response__error(v-if="showResponseError") Debes completar la respuesta {{ getColorName(vitalConfig.responses[vitalConfig.responses.length -1].color) }} para poder agregar una nueva
  
        .row
          fieldset.modal__field  
            el-button(@click="addResponse") + Agregar Respuestas personalizadas
          
</template>

<style lang="scss">
.response {
  border-bottom: 2px solid $light-gray;
  margin-bottom: 20px;
}

.detail__content {
  display: flex;
  flex-direction: column;
}

.days__add {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.subtitle {
  text-align: right;
  margin: 10px;
}

.remove {
  color: $flamingo;
}

.response__error {
  display: block;
  color: $flamingo;
}
</style>
