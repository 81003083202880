var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('section',{staticClass:"detail"},[_c('spinner')],1):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"detail",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('header',{staticClass:"detail__header"},[_c('h2',{staticClass:"detail__headline"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.vitalConfig.name)))]),_c('div',{directives:[{name:"auth",rawName:"v-auth",value:('task-default.create'),expression:"'task-default.create'"}],staticClass:"detail__actions"},[(_vm.isUpdate)?_c('el-button',{attrs:{"type":"danger","disabled":_vm.vitalConfig.isNative},on:{"click":_vm.deleteVitalConfig}},[_vm._v("Borrar")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","native-type":"submit","loading":_vm.isPostingVitalConfig}},[_vm._v("Guardar")])],1)]),_c('p',{staticClass:"subtitle"},[_vm._v("Los campos con (*) son obligatorios")]),(_vm.isFetchingVitalConfig)?_c('div',{staticClass:"modal__content"},[_c('spinner')],1):_c('div',{staticClass:"detail__content"},[_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Nombre")]),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"autofocus":"","disabled":_vm.vitalConfig.isNative},model:{value:(_vm.vitalConfig.name),callback:function ($$v) {_vm.$set(_vm.vitalConfig, "name", $$v)},expression:"vitalConfig.name"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("Descripción")]),_c('el-input',{attrs:{"type":"textarea","rows":"3","maxlength":"250"},model:{value:(_vm.vitalConfig.description),callback:function ($$v) {_vm.$set(_vm.vitalConfig, "description", $$v)},expression:"vitalConfig.description"}})],1)]),_vm._l((_vm.vitalConfig.answerValues),function(answer,index){return _c('div',{staticClass:"answers"},[_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Nombre Respuesta "+_vm._s(index + 1))]),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(answer.name),callback:function ($$v) {_vm.$set(answer, "name", $$v)},expression:"answer.name"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('el-checkbox',{model:{value:(answer.compareToLastMeasurement),callback:function ($$v) {_vm.$set(answer, "compareToLastMeasurement", $$v)},expression:"answer.compareToLastMeasurement"}},[_vm._v("Comparar contra última medición")])],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.green.min),callback:function ($$v) {_vm.$set(answer.measurement.green, "min", $$v)},expression:"answer.measurement.green.min"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Máximo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.green.max),callback:function ($$v) {_vm.$set(answer.measurement.green, "max", $$v)},expression:"answer.measurement.green.max"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Mínima")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.yellow.min),callback:function ($$v) {_vm.$set(answer.measurement.yellow, "min", $$v)},expression:"answer.measurement.yellow.min"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Máxima")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.yellow.max),callback:function ($$v) {_vm.$set(answer.measurement.yellow, "max", $$v)},expression:"answer.measurement.yellow.max"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.red.min),callback:function ($$v) {_vm.$set(answer.measurement.red, "min", $$v)},expression:"answer.measurement.red.min"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Máximo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(answer.measurement.red.max),callback:function ($$v) {_vm.$set(answer.measurement.red, "max", $$v)},expression:"answer.measurement.red.max"}},[(answer.compareToLastMeasurement)?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('small',[_c('a',{on:{"click":function($event){return _vm.removeAnswer(index)}}},[_vm._v("× Eliminar Respuesta "+_vm._s(index + 1))])])])])])}),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[(!!_vm.vitalConfig.answerValues && _vm.showAnswerError)?_c('span',{staticClass:"days-error"},[_vm._v("Debes completar la respuesta para poder agregar una nueva")]):_vm._e()])]),_c('div',{staticClass:"modal__row days__add"},[_c('fieldset',{staticClass:"modal__field"},[_c('a',{on:{"click":_vm.addAnswer}},[_vm._v("+ Agregar Respuesta")])])]),_vm._l((_vm.vitalConfig.responses),function(response,index){return _c('div',{key:index,staticClass:"response"},[_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("Respuesta "+_vm._s(_vm.getColorName(response.color)))])])]),_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":function($event){return _vm.removeResponse(index)}}},[_vm._v("×")])],1),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Alerta:")]),_c('ValidationProvider',{attrs:{"name":"Alerta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"placeholder":"Seleccionar nivel Alerta","filterable":"","clearable":"","default-first-option":""},model:{value:(response.color),callback:function ($$v) {_vm.$set(response, "color", $$v)},expression:"response.color"}},_vm._l((_vm.colorNames),function(colorName){return _c('el-option',{key:colorName.value,attrs:{"label":colorName.label,"value":colorName.value,"disabled":_vm.isUsed(colorName.value)}})}),1)]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Mensaje:")]),_c('ValidationProvider',{attrs:{"name":"Mensaje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(response.message.text),callback:function ($$v) {_vm.$set(response.message, "text", $$v)},expression:"response.message.text"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("Color mensaje:")]),_c('el-color-picker',{attrs:{"placeholder":"Seleccione un color de texto","type":"color","clearable":"","name":"Color","rows":"1"},model:{value:(response.message.textColor),callback:function ($$v) {_vm.$set(response.message, "textColor", $$v)},expression:"response.message.textColor"}})],1)]),_vm._l((response.media),function(media,mediaIndex){return _c('div',{staticClass:"response-media"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("Recomendación "+_vm._s(mediaIndex +1))])])]),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tipo de Recomendación:")]),_c('ValidationProvider',{attrs:{"name":("Tipo Archivo Respuesta " + (_vm.getColorName(response.color))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{on:{"change":function($event){return _vm.resetMediaContent(media)}},model:{value:(media.type),callback:function ($$v) {_vm.$set(media, "type", $$v)},expression:"media.type"}},[_c('el-option',{attrs:{"label":"Imagen","value":"image"}}),_c('el-option',{attrs:{"label":"Audio","value":"audio"}}),_c('el-option',{attrs:{"label":"Video","value":"video"}})],1),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Título:")]),_c('ValidationProvider',{attrs:{"name":("Titulo Archivo Respuesta " + (_vm.getColorName(response.color))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(media.title),callback:function ($$v) {_vm.$set(media, "title", $$v)},expression:"media.title"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("Descripción:")]),_c('el-input',{model:{value:(media.description),callback:function ($$v) {_vm.$set(media, "description", $$v)},expression:"media.description"}})],1)]),(media.type === 'image' || media.type === 'audio')?_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[(media.type === 'image')?_c('label',{staticClass:"label"},[_vm._v("Imagen:")]):_c('label',{staticClass:"label"},[_vm._v("Audio:")]),_c('file-upload',{ref:"fileUpload",refInFor:true,attrs:{"type":media.type,"url":_vm.uploadMediaEndpoint,"files":_vm.getFiles(media),"dropzone-options":_vm.getDropdownOptions()},on:{"file-added":function (file) { return _vm.onFilesAdded(file, media); },"file-removed":function (file) { return _vm.onFileRemoved(file, media); },"files-removed":_vm.onFilesRemoved,"fileupload-success":_vm.onFileUploadSuccess,"fileupload-error":_vm.onMediaError}})],1)]):_vm._e(),(media.type === 'video')?_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Link del Video:")]),_c('ValidationProvider',{attrs:{"name":"Link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},model:{value:(media.content[0].value),callback:function ($$v) {_vm.$set(media.content[0], "value", $$v)},expression:"media.content[0].value"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('small',[_c('a',{staticClass:"remove",on:{"click":function($event){return _vm.removeMedia(response, mediaIndex)}}},[_vm._v("× Eliminar recomendación "+_vm._s(mediaIndex +1))])])])])])}),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"modal__field"},[(response.media.length && _vm.showMediaResponseError)?_c('span',{staticClass:"response__error"},[_vm._v("Debes completar la recomendación de la respuesta "+_vm._s(_vm.getColorName(response.color))+" para poder agregar una nueva")]):_vm._e(),_c('a',{on:{"click":function($event){return _vm.addMedia(response)}}},[_vm._v("+ Agregar recomendación")])])]),_c('div',{staticClass:"row"},[_c('fieldset',[(_vm.showResponseError)?_c('span',{staticClass:"response__error"},[_vm._v("Debes completar la respuesta "+_vm._s(_vm.getColorName(_vm.vitalConfig.responses[_vm.vitalConfig.responses.length -1].color))+" para poder agregar una nueva")]):_vm._e()])])],2)}),_c('div',{staticClass:"row"},[_c('fieldset',{staticClass:"modal__field"},[_c('el-button',{on:{"click":_vm.addResponse}},[_vm._v("+ Agregar Respuestas personalizadas")])],1)])],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }